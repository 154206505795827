:root {
  --rootColorFore1: #fff;
  --rootColorFore2: #fff;
  --rootColorFore3: #fff;
  --rootColorFore4: #000;
  --rootColorBg1: #000;
  --rootColorBg2: #fff;
  --rootColorBg3: #fff;
  --rootColorBg4: #fff;
  --bgimg: url(../public/assets/images/home-bg3.ef171b05.jpg);
}
.rootColorFore1 {
  color: var(--rootColorFore1) !important;
}
.rootColorFore2 {
  color: var(--rootColorFore2) !important;
}

.rootColorFore3 {
  color: var(--rootColorFore3) !important;
}
.rootColorFore4 {
  color: var(--rootColorFore4) !important;
}
.rootColorBg1 {
  background-color: var(--rootColorBg1) !important;
}
.rootColorBg2 {
  background-color: var(--rootColorBg2) !important;
}
.rootColorBg3 {
  background-color: var(--rootColorBg3) !important;
}
.rootColorBg4 {
  background-color: var(--rootColorBg4) !important;
}

.bgimg::before {
  background-image: var(--bgimg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* fontweight Classes start */
.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}
/* fontweight Classes end */

/* fontsize rem Classes start */
.fs-rem-1 {
  font-size: 1rem !important;
}

.fs-rem-2 {
  font-size: 2rem !important;
}

.fs-rem-3 {
  font-size: 3rem !important;
}

.fs-rem-4 {
  font-size: 4rem !important;
}

.fs-rem-5 {
  font-size: 5rem !important;
}

.fs-rem-6 {
  font-size: 6rem !important;
}

.fs-rem-7 {
  font-size: 7rem !important;
}

/* fontsize rem Classes end */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.search-icon {
  background-color: inherit !important;
}

.cv-icons-hover:hover {
  color: var(--rootColorFore3) !important;
}

.bg-pending {
  background-color: #e4e4f3;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cv-border {
  box-shadow: 2px 2px 1px #606060;
  line-height: 2em;
  border-radius: 1em;
}
a {
  color: #bb1e1e;
}
.experience-list li {
  list-style-type: disc;
}
.site-main {
  background: white;
  height: 100vh;
}
.portfolio-technologies:hover {
  color: white;
  text-decoration: underline !important;
}

.porfolio-list li {
  font-weight: lighter;
}

.list-container {
  height: 100%;
  overflow-y: auto;
}

.customer-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
  background: #0c1226;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 350px;
}
.customer-card h3 {
  background-color: #eae5e5;
  padding: 20px;
  border-radius: 70px;
  margin-bottom: 40px;
}
.customer-card h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
}

.customer-card p {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.secnd-h_container h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  font-family: 'Poppins', sans-serif;

  text-align: center;

  color: #3a3a3a;
  margin-top: 60px;
}
.social-icons-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons-card h3 {
  margin: 20px 0px;
}

.social-icons-card h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #000000;
}

.social-icons-card p {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;

  font-family: 'Poppins', sans-serif;

  text-align: center;

  color: #3a3a3a;
}

.form-h-p-container h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;

  font-family: 'Poppins', sans-serif;

  text-align: center;

  color: #3a3a3a;
}
.form-h-p-container p {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  font-family: 'Poppins', sans-serif;

  text-align: center;

  color: #3a3a3a;
}
.f-l-name-container {
  display: flex;
  justify-content: space-between;
}

.f-l-name-container input {
  font-family: 'Poppins', sans-serif;

  width: 49%;
  padding: 10px 15px;
  background: #eae5e5;
  border: 1px solid rgba(12, 18, 38, 0.35);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  color: #5e5e5e;
}
.f-l-name-container input:focus {
  box-shadow: none;
}
.email-container {
  margin: 20px 0px;
}

.email-container input {
  padding: 10px 15px;
  background: #eae5e5;
  border: 1px solid rgba(12, 18, 38, 0.35);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  color: #5e5e5e;
}
.email-container input:focus {
  box-shadow: none;
}

.phone-container {
  margin: 20px 0px;
}

.phone-container input {
  padding: 10px 15px;
  background: #eae5e5;
  border: 1px solid rgba(12, 18, 38, 0.35);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  color: #5e5e5e;
}
.phone-container input:focus {
  box-shadow: none;
}
.textarera {
  background: #eae5e5;
  color: #5e5e5e;
  padding-left: 15px;
}
.textarera:focus {
  box-shadow: none;
}

.form-btn-container {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}
.form-btn-container button {
  background-color: #29aae1;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 8px 45px;
  font-size: 26px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .customer-card {
    margin: 20px 0px;
  }
}

.portfolio_detail_button:hover {
  text-decoration: underline !important;
}

.card-header {
  border-bottom: none !important;
}
.form-check-input:checked {
  background-color: #405189;
  border-color: #405189;
}
.main-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.admin-mode span {
  font-weight: 600;
  text-decoration: underline;
  color: black;
}

.swal-class {
  width: 20em;
  margin-bottom: 20px;
  padding: 0px 0px 0.6em;
}

.dynamicGeneration_coverLetter .ck-editor__editable {
  min-height: 80px !important;
}

.modifiedText,
.modifiedText a {
  color: var(--rootColorFore4) !important;
  font-weight: 500 !important;
}

.tech-list-style {
  border-radius: 0.5em;
}

.tech-list-style::first-letter {
  text-transform: capitalize !important;
}

.active-list-item:active {
  color: red;
}

.list-main {
  color: red;
}

.upwork-svg svg {
  fill: var(--rootColorFore2) !important;
}

.upwork-svg1 svg {
  fill: var(--rootColorFore3) !important;
}

.sidesection {
  background-color: inherit !important;
  text-align: left !important;
  text-transform: capitalize !important;
}

.contact_info {
  text-decoration: underline !important;
}
.teck-stack {
  color: #878aa9 !important;
}

.hr-dotted {
  border: none; /* Remove default border */
  border-top: 2px dotted var(--rootColorFore1);
  margin: 10px 0; /* Adjust margin as per your design */
}

.padding_class1 {
  padding-right: 0.8em !important;
}

.padding_class2 {
  padding-right: 0.3em !important;
}

.color-div {
  height: 1.2em;
  width: 1.2em;
  /* border-radius: 50%; */
}

.line-height {
  line-height: 2em;
}

.icon-color {
  color: var(--rootColorFore3) !important;
}
.hover-img,
a {
  transition: transform 0.3s, filter 0.3s;
}
.hover-img:hover,
a:hover {
  transform: scale(1.1);
  filter: brightness(1.1);
}
.sitebar-list ul li a:hover,
.cv-header div a:hover,
.nav-item a:hover,
.nav-no-hover a:hover {
  transform: none !important;
}
.hover-bg:hover {
  font-weight: 600 !important;
}
.title-break {
  word-wrap: break-word !important;
}

.red-date {
  color: var(--rootColorFore1) !important;
}

.accordion-button.rotate::after {
  transform: rotate(0deg);
}
.line-height {
  line-height: 1.75 !important;
}
.json-object tbody tr:last-child th,
.json-object tbody tr:last-child td {
  border-bottom-width: 0px !important;
}

.before {
  background-color: #e3e9fd !important;
}

.borderRadius {
  border-radius: 2em !important;
}

.nestedAccordion {
  background-color: rgb(228, 243, 240) !important;
}

h1,
h2,
h3,
h4,
p,
li span,
strong {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.more-pictures {
  color: var(--rootColorFore4);
}

.more-pictures:hover {
  text-decoration: underline;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: rgb(180, 173, 173);
  border-radius: 5px;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  background-color: inherit;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (min-width: 991px) {
  .cv-head {
    padding-top: 2.3em;
    padding-bottom: 2.3em;
  }
}

@media (min-width: 999px) {
  .main-content1 {
    margin-left: 18%;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .sitebar-list {
    height: 4em;
    overflow-y: auto;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1380px) {
  .sitebar-list {
    height: 10em;
    overflow-y: auto;
  }
}

@media (min-width: 990px) {
  .cv-section-main,
  .cv-header img {
    height: 11.5em;
    object-fit: cover;
  }
  .cv-portfolio-img {
    float: right;
    width: 33.334%;
    padding: 0.5em;
  }
}

@media (min-width: 765px) {
  .technology-left-border {
    border-left: 1px solid #ddd;
    padding-left: 1.2em;
  }
  .border-right-cv {
    border-right: 1px solid #c1c1c1;
  }
}

.screen-hidden {
  display: none;
}

@media print {
  /* @page {
    size: 11in 17in;
  } */

  body {
    background-color: white;
  }

  .hidden_portfolio_button {
    display: none;
  }
  .cv-border {
    border-radius: 0 !important;
    border: none !important;
  }

  .screen-hidden {
    display: block;
  }

  .screen-shown {
    display: none !important;
  }

  .custom-span {
    background-color: yellow; /* Example styling */
  }

  .print-time {
    padding-left: 7em;
  }
  .print-time1 {
    padding-left: 3.7em;
  }
  .technology-left-border {
    border-left: 0px solid #ddd;
    padding-left: 0;
  }
}

.services-accordion:hover {
  cursor: pointer;
}
